import { Component, OnDestroy, OnInit } from '@angular/core';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit{
  media$ : Observable<MediaChange[]>;
  constructor(public media: MediaObserver ) {
    this.media$ = this.media.asObservable();
  }
  ngOnInit(): void {
      // this.media$.subscribe(mq => {
      //   console.log("Media Query", mq[0].mqAlias);
      // })
  }
}
