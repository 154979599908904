import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/app/environments/environment';
import { BootController } from '../boot.controller';
import {
  UserConfirmSignUpRequest,
  UserForgotPasswordSetNewPassword,
  UserSignInRequest,
  UserSignInResponse,
  UserSignUpRequest,
} from '../shared/model/user';
import {
  LoggedUser,
  Profile,
  UserCompanyProfile,
} from './session/loggeduser.model';
import { StorageService } from './session/storage.service';
import { InviteLinkRequestDto } from '../shared/model/inviteLinkRequestDto';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private API_URL = `${environment.apiBaseUrl}/api/v1/auth`;
  private API_URL_INVITATION = `${environment.apiBaseUrl}/api/v1`;
  redirectUrl?: string;

  constructor(
    private http: HttpClient,
    public storage: StorageService,
    private router: Router,
    private ngZone: NgZone,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
  ) {}

  signUp(userSignUp: UserSignUpRequest): Observable<void> {
    return this.http.post<void>(`${this.API_URL}/signUp`, userSignUp);
  }

  refreshToken(): Observable<{ idToken: string }> {
    const refreshToken = this.storage.getRefreshToken();
    const userSub = this.storage.getLocalUser()?.sub;
    return this.http
      .post<{ idToken: string }>(`${this.API_URL}/refresh-token`, {
        refreshToken: refreshToken,
        sub: userSub,
      })
      .pipe(
        tap((response) => {
          const newToken = response.idToken;
          let loggedUser = this.extractUserInfoFromToken(newToken);
          this.storage.setLocalUser(loggedUser);
        }),
      );
  }

  sucessfullLogin(user: LoggedUser, refreshToken: string) {
    this.storage.setLocalUser(user);
    this.storage.setRefreshToken(refreshToken);
    const userCompanyProfile = this.storage.getUserCompanyProfile();
    if (userCompanyProfile === null) {
      this.storage.setUserCompanyProfile(UserCompanyProfile.ORGANIZER);
    }
  }

  confirmSignup(
    userEmail: string,
    userConfirmSignUpRequest: UserConfirmSignUpRequest,
  ): Observable<{ idToken: string; refreshToken: string }> {
    return this.http.patch<{ idToken: string; refreshToken: string }>(
      `${this.API_URL}/${userEmail}/confirm`,
      userConfirmSignUpRequest,
    );
  }

  signIn(userSignIn: UserSignInRequest): Observable<UserSignInResponse> {
    return this.http.post<UserSignInResponse>(
      `${this.API_URL}/signIn`,
      userSignIn,
    );
  }

  forgotPasswordRequestCode(userEmail: string): Observable<void> {
    return this.http.put<void>(
      `${this.API_URL}/${userEmail}/forgot-password`,
      null,
    );
  }

  forgotPasswordSetNewPassword(
    userEmail: string,
    obj: UserForgotPasswordSetNewPassword,
  ): Observable<void> {
    return this.http.put<void>(
      `${this.API_URL}/${userEmail}/new-password`,
      obj,
    );
  }

  verifyInvitationLink(email: string, code: string, nationalId: string): Observable<InviteLinkRequestDto> {
    const url = `${this.API_URL_INVITATION}/invitationLink`;
    const params = { email, code, nationalId };
    return this.http.get<InviteLinkRequestDto>(url, { params });
  }
  
  acceptInvitation(code: string, email: string, nationalId: string, companyId?: number): Observable<any> {
    return this.http.patch<any>(`${this.API_URL_INVITATION}/invitationLink/accept/${companyId}`, {
      email,
      nationalId,
      code, 
    });
  }

  rejectInvitation(code: string, email: string, nationalId: string): Observable<any> {
    return this.http.patch<any>(`${this.API_URL_INVITATION}/invitationLink/reject`, {
      email,
      nationalId,
      code
    });
  }

  logout(path?: string) {
    if (path) {
      this.router.navigate(['/'], {
        queryParams: { afterLoginRedirectTo: path },
      });
    } else {
      this.router.navigate(['/']);
    }

    this.storage.setLocalUser(null);
    this.storage.setRefreshToken(null);
    this.storage.setUserCompanyProfile(UserCompanyProfile.ORGANIZER);
    this.storage.setRefreshToken(null);
    // Triggers the reboot in main.ts
    this.ngZone.runOutsideAngular(() =>
      BootController.getbootControl().restart(),
    );
  }

  isLoggedIn(): boolean {
    return this.storage.getLocalUser() != null;
  }

  handleLogin(path?: string) {
    this.toastr.info(
      'Não autenticado.',
      'Por favor, realize login ou crie uma nova conta.',
      { progressBar: true, timeOut: 5000, closeButton: true },
    );
    this.logout(path);
  }

  extractUserInfoFromToken(token: string): LoggedUser | null {
    try {
      const payload = token.split('.')[1];
      const decodedPayload = JSON.parse(atob(payload));

      const correctAccentuation = (text: string): string => {
        const corrections: { [key: string]: string } = {
            'Ã¡': 'á', 'Ã©': 'é', 'Ã­': 'í', 'Ã³': 'ó', 'Ãº': 'ú',
            'Ã¢': 'â', 'Ãª': 'ê', 'Ã´': 'ô',
            'Ã£': 'ã', 'Ã§Ã': 'ç',
            // Versões maiúsculas
            'Ã\u0081': 'Á', 'Ã\u0089': 'É', 'Ã\u008D': 'Í', 'Ã\u0093': 'Ó', 'Ã\u009A': 'Ú',
            'Ã\u0082': 'Â', 'Ã\u008A': 'Ê', 'Ã\u0094': 'Ô',
            'Ã\u0083': 'Ã', 'Ã\u0087': 'Ç'
        };
        const regex = new RegExp(Object.keys(corrections).join('|'), 'g');
        return text.replace(regex, (match) => corrections[match]);
    };

      const user: LoggedUser = {
        fullName: correctAccentuation(decodedPayload.name || ''),
        email: decodedPayload.email || '',
        companyId: decodedPayload['custom:companyId'] || null,
        profile: this.getProfileFromToken(decodedPayload),
        token: token,
        sub: decodedPayload.sub,
      };
      
      return user;
    } catch (error) {
      console.error('Error decoding token:', error);
      return null;
    }
  }

  private getProfileFromToken(decodedPayload: any): Profile {
    const profileValue = decodedPayload['profile'];
    if (profileValue === 'ADMIN') {
      return Profile.ADMIN;
    } else if (profileValue === 'COMPANY') {
      return Profile.COMPANY;
    } else {
      throw new Error('Invalid profile value in token');
    }
  }

  getLoggedUser(): LoggedUser | null {
    const name = this.storage.getLocalUser();
    return name;
  }

  isTokenExpired(): boolean {
    const user = this.storage.getLocalUser();
    if (!user || !user.token) return true;
    const expiry = JSON.parse(atob(user.token.split('.')[1])).exp;
    return Math.floor(new Date().getTime() / 1000) >= expiry;
  }

  refreshTokenAndRedirectTo(path: string) {
    this.spinner.show();
    this.refreshToken().subscribe(
      (res) => {
        setTimeout(() => {
          this.router
            .navigateByUrl('/', { skipLocationChange: true })
            .then(() => {
              this.spinner.hide();
              this.router.navigate([path]);
            });
        }, 500);
      },
      (error) => {
        this.spinner.hide();
        console.error(error);
        this.toastr.error(
          'Não foi possível completar sua requisição.',
          'Por favor, entre em contato caso o problema persista.',
          { progressBar: true, timeOut: 5000, closeButton: true },
        );
      },
    );
  }

  resendConfirmationCode(email: string): Observable<void> {
    const body = { email };
    return this.http.post<void>(`${this.API_URL}/resendConfirmationCode`, body);
  }
}
