import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { MatPasswordStrengthModule } from "@angular-material-extensions/password-strength";
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import ptBr from '@angular/common/locales/pt';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from '@angular/material/form-field';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxEditorModule } from 'ngx-editor';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './auth/auth.service';
import { LoggedInGuard } from './auth/guard/loggedin.guard';
import { PermissionGuard } from './auth/guard/permission.guard';
import { AuthInterceptor } from './auth/interceptor/auth.interceptor';
import { StorageService } from './auth/session/storage.service';
import { CurrencyBrPipeModule } from './shared/pipe/currency-br.pipe.module';

registerLocaleData(ptBr);

const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline',
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgxMaskDirective,
    NgxMaskPipe,
    HttpClientModule,
    NgxSpinnerModule,
    CurrencyBrPipeModule,
    NgxEditorModule.forRoot({
      locals: {
        // menu
        bold: 'Negrito',
        italic: 'Itálico',
        code: 'Código',
        underline: 'Sublinhado',
        strike: 'Tachado',
        blockquote: 'Citação',
        bullet_list: 'Lista com Marcadores',
        ordered_list: 'Lista Ordenada',
        heading: 'Título',
        h1: 'Título 1',
        h2: 'Título 2',
        h3: 'Título 3',
        h4: 'Título 4',
        h5: 'Título 5',
        h6: 'Título 6',
        align_left: 'Alinhar à Esquerda',
        align_center: 'Centralizar',
        align_right: 'Alinhar à Direita',
        align_justify: 'Justificar',
        text_color: 'Cor do Texto',
        background_color: 'Cor de Fundo',
        insertLink: 'Inserir Link',
        removeLink: 'Remover Link',
        insertImage: 'Inserir Imagem',

        // popups, forms, outros...
        url: 'URL',
        text: 'Texto',
        openInNewTab: 'Abrir em nova aba',
        insert: 'Inserir',
        altText: 'Texto Alternativo',
        title: 'Título',
        remove: 'Remover',
      },
    }),
    MatPasswordStrengthModule.forRoot()
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: appearance,
    },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    provideNgxMask(),
    AuthService,
    StorageService,
    LoggedInGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    PermissionGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
