import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root',
})
export class LoggedInGuard implements CanLoad, CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canLoad(route: Route): boolean {
    const path = route.path ? `/${route.path}` : '/';
    return this.checkAuthentication(path, 'canLoad');
  }

  canActivate(
    activatedRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    return this.checkAuthentication(state.url, 'canActivate');
  }

  checkAuthentication(url: string, source: 'canLoad' | 'canActivate'): boolean {
    const loggedIn = this.authService.isLoggedIn();
    if (!loggedIn) {
      this.authService.handleLogin(url);
    }
    return loggedIn;
  }
}
