import { NgModule } from '@angular/core';
import { CapitalizePipe } from './capitalize.pipe';
import { CurrencyBrPipe } from './currency-br.pipe';

@NgModule({
  declarations: [CurrencyBrPipe],
  imports: [],
  exports: [CurrencyBrPipe],
})
export class CurrencyBrPipeModule {}
