export interface LoggedUser {
  fullName: string;
  email: string;
  profile: Profile;
  token: string;
  companyId: number;
  sub: string;
}

export enum Profile {
  ADMIN = 'ADMIN',
  COMPANY = 'COMPANY',
}

export enum UserCompanyProfile {
  ORGANIZER = 'ORGANIZER',
  EXPOSITOR = 'EXPOSITOR',
}
