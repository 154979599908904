import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Profile } from '../session/loggeduser.model';
import { StorageService } from '../session/storage.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionGuard implements CanActivate {
  constructor(
    private storageService: StorageService,
    private toastr: ToastrService,
    private router: Router,
  ) {}

  canActivate(
    activatedRoute: ActivatedRouteSnapshot,
    routerState: RouterStateSnapshot,
  ): boolean {
    if (
      !this.havePermission(
        this.storageService.getLocalUser()!.profile,
        activatedRoute.data['roles'],
      )
    ) {
      this.toastr.error('Você não tem acesso a isso.', 'Permissão', {
        closeButton: true,
        progressBar: true,
      });
      this.router.navigate(['/auth/signin']);
      return false;
    }
    return true;
  }

  havePermission(profile: Profile, profiles: Profile[]): boolean {
    for (var p of profiles) {
      if (p == profile) {
        return true;
      }
    }
    return false;
  }
}
