import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyBr',
})
export class CurrencyBrPipe implements PipeTransform {
  transform(value: number | undefined): string {
    if (value === undefined) return "";
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  }
}
